<template>
  <div>

    <v-container fluid grid-list-md>
      <h3>Priority Status</h3>
     <!-- <div id="timer">
      05:00
     </div>  -->
      <v-layout row wrap>

        <v-flex v-for="(item, index, idx) in priorityList" :key="index" xs12 md4 lg4>
          <v-card width="100%" height="100%" style="cursor: pointer;" :class="{ 'selectedItem': activeId === item.id }">
            <div class="pa-4 mb-1 custom-card" @click="openDailog(item, idx), activeId = item.id">
              <div class="d-flex justify-space-between">
                <div class="mb-1">
                  <label for="" class="fsize16">{{  item.project_name  }}</label>
                </div>
                <div class="mb-1">
                  <label for="" class="fsize16 px-2 py-1 label-color"
                    v-bind:class="item.task_type == 'past' ? 'bg-red' : item.task_type == 'future' ? 'bg-green' : item.task_type == 'current' ? 'yellow' : ''">{{
                     item['end_date'] 
                    }}</label>
                </div>
              </div>

              <div class="mt-2 d-flex justify-space-between">
                <label for="" class="fsize16 mx-w">{{  item.task_name  }}</label>
                <label for="" class="fsize16 f-right">{{  item.assigned_to_name ? item.assigned_to_name : '--'  }}</label>
              </div>

            </div>

          </v-card>

        </v-flex>

      </v-layout>

      <div v-if="priorityList.length == 0">
        <div class="d-flex align-center align-label">
          No Status Available
        </div>
      </div>
      <div class="h-20">

      </div>

      <h3>Critical Status</h3>
      <v-layout row wrap>

        <v-flex v-for="(item, index) in criticalTasks" :key="index" xs12 md6 lg3>
          <v-card width="100%" height="100%" v-ripple style="cursor: pointer;"
            :class="{ 'selectedItem': activeId === item.id }">
            <div class="pa-4 mb-1" @click="criticalData(item), activeId = item.id">


              <div class="d-flex justify-space-between">
                <div class="mb-1">

                  <label for="" class="fsize16 ">{{  item.task_name  }}</label>
                </div>

                <div class="mb-1">

                  <label for="" class="fsize16 px-2 py-1 label-color"
                    v-bind:class="item.task_type == 'past' ? 'bg-red' : item.task_type == 'future' ? 'bg-green' : item.task_type == 'future' ? 'green' : ''">{{
                     item['end_date'] 
                    }}</label>
                </div>



              </div>

              <div class="mt-2">
                <label for="" class="fsize16 mx-w">{{  item.task_name  }}</label>
                <label for="" class="fsize16 f-right">{{  item.task_manager ? item.task_manager : '--'  }}</label>
              </div>



            </div>
          </v-card>
        </v-flex>
      </v-layout>
      <div v-if="criticalTasks.length == 0">
        <div class="d-flex align-center align-label">
          No Status Available
        </div>
      </div>

    </v-container>
    <v-dialog class="custom-dailog" v-model="dialog" max-width="600">
      <v-card hover>
        <v-card-title class="text-h5">
          Description
        </v-card-title>

        <v-card-text>
          {{  descriptionData  }}
        </v-card-text>

        <v-card-title class="text-h5">
          Comments
        </v-card-title>

        <v-card-text disabled class="copy">
          {{  commentsData ? commentsData : 'No comments Found'  }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined text dark @click="popUp()">
            Add comment<v-icon dark right>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn color="primary" text dark @click="cancelPopup(1)">
            Cancel
          </v-btn>


        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog class="custom-dailog" v-model="criticalStatus" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Description
        </v-card-title>

        <v-card-text>
          {{  criticalDescription  }}
        </v-card-text>

        <v-card-title class="text-h5">
          Comments
        </v-card-title>

        <v-card-text disabled class="copy">
          {{  commentsData ? commentsData : 'No comments Found'  }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined text dark @click="popUp(1)">
            Edit<v-icon dark right>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn color="primary" text dark @click="cancelPopup(2)">
            Cancel
          </v-btn>


        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer class="updatepanel" v-model="updatePanel" overlay-color="transparent" :width="panelWidth" fixed
      v-if="comentPanel" right>

      <div class="d-flex pa-2 bg-f5 align-center updatePanelHead">
        <p class="mb-0">{{  taskName  }}</p>
        <v-spacer></v-spacer>
        <v-icon color="red" :ripple="false" @click="closePanel()">mdi-close-circle</v-icon>
      </div>
      <v-progress-linear :active="loading" :indeterminate="loading" absolute top rounded height="3" color="red">
      </v-progress-linear>

      <div class="d-flex pt-10">
        <div v-if="expandPanel">
          <div class="pa-4 commentsPart updateForm">comments</div>
        </div>
        <div class="my-1 sidePanel rounded-lg d-flex flex-column align-end user-select-none">
          <div class="pt-4 updateForm" :style="expandPanel ? 'max-width: 50%' : 'max-width: 100%'">
            <div class="ml-4">
              <h4>Task Comments</h4>
              <div>

                <p class="fsize12 mt-2">Created By {{  empName  }} on {{  createdOn  }}</p>
              </div>
            </div>
            <div class="border-hr mx-4">

            </div>
            <div>
              <p class="fsize12 mt-2 ml-3">
                <v-icon>mdi-account</v-icon><span class="ml-2 vertical-align font-bold">{{  empName  }}</span>
              </p>
              <div></div>
            </div>
            <div class="ml-4 mt-2">
              <h4>Add new comments</h4>
            </div>
            <p class="mb-0 ml-8">Comments</p>
            <vue-editor @image-added="handleImageAdded" class="pa-4" v-model="comments"
              :class="{ 'is-valid': submitteValid && comments == '' }">
              <div class="fsize12 text-danger marg-t-4" v-if="this.submitteValid && this.comments == ''">
                Please enter your comments
              </div>
            </vue-editor>

          </div>

          <div>
            <v-btn depressed color="light-blue" @click="saveTask()" class="text-capitalize white--text mr-4">Save
            </v-btn>
          </div>
        </div>




      </div>


    </v-navigation-drawer>


  </div>
</template>
<script>

import { mapGetters, mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  mixins: [],
  components: { VueEditor },


  data: () => ({

    dialog: false,
    criticalStatus: false,
    comentPanel: false,
    state: "close",
    descriptionData: "",
    criticalDescription: "",
    criticalDate: "",
    endDate: "",
    taskName: "",
    criticalTaskName: "",
    empName: "",
    currentDate: "",
    commentsData: "",
    dateObj: '',
    activeId: undefined,
    empRoleId: "",
    comments: "",
    taskId: "",
    priorityStatus: "",
    actualHour: "",
    taskStatus: "",
    Id: "",
    loading: false,
    submitteValid: false


  }),

  computed: {
    ...mapGetters("task", {
      priorityList: "getPriorityTask",
      totalTasks: "getAllTasks",
      currentUserTasks: "getCurrentUserTaks",
      currentTasks: "getCurrentTasks",
      openTasks: "getOpenTasks",
      inProgressTasks: "getInProgressTasks",
      fixedTasks: "getFixedTasks",
      cancelledTasks: "getCancelledTasks",
      pendingTasks: "getPendingTasks",
      reviewTasks: "getReviewTasks",
      completedTasks: "getCompletedTasks",
      criticalTasks: "getCriticalTask",

    }),
    ...mapGetters({
      sidePanelState: "getUpdateTaskPanel",
      expandPanelBool: "getExpandPanel",
      panelWidth: "getPanelWidth",
    }),

    ...mapState(["employeRecord", "projecttList"]),

    updatePanel: {
      get() {
        return this.sidePanelState;
      },
      set(val) {
        this.$store.commit("setUpdateTaskPanel", val);
      },
    },
    expandPanel: {
      get() {
        return this.expandPanelBool;
      },
      set(val) {
        this.$store.commit("setExpandPanel", val);
      },
    },
  },

  created() {
    this.$store.dispatch("task/getTasks", { root: true })
  },
  mounted() {

    // setTimeout(() => {
    //   this.$router.push('/').catch(() => {});
    // },  2*60*60*1000);


    var specific_date = new Date().toISOString().slice(0, 10);
    var current_date = new Date().toISOString().slice(0, 10);
    if (current_date > specific_date) {}
    else {}
    var date2 = new Date()

    this.$store.dispatch("getEmployeeData", { root: true });
    var date = new Date();
    var formattedDate = `${date.getDate()}-${(date.getMonth() + 1)}-${date.getFullYear()}`;

    this.currentDate == new Date()

    this.enddate = JSON.parse(localStorage.getItem('endDate'))
  },
  methods: {
    openDailog(val) {
      this.dialog = true
      this.descriptionData = val.description
      this.taskName = val.task_name
      this.empName = val.assigned_to_name
      this.commentsData = val.comments
      this.createdOn = val.start_date
      this.taskId = val.task_id
      this.actualHour = val.est_hour
      this.assignedTo = val.assigned_to
      this.priorityStatus = val.priority
      this.taskStatus = val.task_status
      this.Id = val.id
    },
    cancelPopup(val) {
      if (val == 1) {
        this.dialog = false
        this.activeId = undefined
      }
      else if (val == 2) {
        this.criticalStatus = false
        this.activeId = undefined
      }


    },
    criticalData(data) {
      this.criticalStatus = true
      this.criticalDescription = data.description
      this.criticalDate = data.end_date
      this.criticalTaskName = data.task_name
    },
    popUp() {
      this.empRoleId = (localStorage.getItem('adminRole') == 1);
      this.comentPanel = true
      this.$store.commit("setUpdateTaskPanel", "open");
      this.commentDailog = true
      this.dialog = false
      this.activeId = undefined
    },
    closePanel() {
      this.comentPanel = false
      this.comments = ''
    },
    async saveTask() {
      this.submittedLoan = true;
      if (this.comments == '') {
        return false;
      }
      this.loading = true
      this.comentPanel = false
      let jsonObj = {
        comment: this.comments,
        taskid: this.Id,
        actualhour: this.actualHour,
        assignedTo: this.assignedTo,
        priorityStatus: this.priorityStatus,
        taskStatus: this.taskStatus,
        id: this.Id,
      }
   
      await this.$store.dispatch("updateTaskDetails", jsonObj)
      this.loading = false
      this.comments = ''
    },
    handleImageAdded() {

      var formData = new FormData();
      formData.append("image", file);


    },
  }
};
</script>

<style scoped>
.time-slot-md-small {
  border-radius: 10px;
  font-size: 10px;
  height: 16px;
}

.v-progress-circular {
  margin: 1rem;
}

.colorgrey {
  color: #9b9b9b !important;
  font-size: 16px;
}

.description {
  font-size: 16px;
  line-height: 22px;
}

.f-right {
  float: right;
}

.label-align {
  font-size: 14px;
  background-color: #feefe3;
  color: #e37400;
  height: 26px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
}

.critical-clr {
  background-color: #f6e8e6;
  color: #d93025;
  height: 26px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  font-size: 14px;
}

.h-20 {
  height: 20px !important;
}

.align-label {
  height: 10vh;
  justify-content: flex-start;
}

.v-card {
  border-radius: 8px !important;
}

.custom-dailog.v-dialog {
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}

.custom-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 4%) !important;
  border-radius: 8px !important
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.mx-w {
  max-width: 285px !important;
}

.v-application .red {
  background-color: #f44336 !important;
  border-color: #f44336cf !important;
  color: white;
  padding: 4px;
  border-radius: 4px;
}

.v-dialog {
  border-radius: 0px !important;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}

.label-color {
  color: white;
  border-radius: 2px;
}

.copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.selectedItem {
  /* //background-color: #2196f3 !important; */
  border: 1px solid #2196f3;
}

.bg-red {
  background-color: #ffd5d5;
  color: #ed4444;
}

.bg-green {
  background-color: #a4d4a48a;
  color: #1b8d1b;
}

.openedPanel .taskswrapper {
  width: calc(100% - 400px) !important;
}

.taskswrapper {
  width: 100%;
}

.calcHeightPanel {
  height: calc(100vh - 176px) !important;
}

.text-overflow {
  width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.updateForm {
  max-height: 650px;
  overflow-y: auto;
}

.bg-f5 {
  background-color: #0066ccb5;
  color: white;
}

.sidePanel {

  box-shadow: none !important;

}


.updatepanel {
  top: 64px !important;
  height: calc(100vh - 64px) !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.commentsPart {
  height: calc(100vh - 56px) !important;
  overflow-y: auto;
}

.updatePanelHead {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.grey-clr {
  background-color: #ededed;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 32px !important;
}

.updatepanel .v-navigation-drawer__border {
  width: 0px !important;
}

.border-hr {
  border-bottom: 1px solid #8080803d;

}

.vertical-align {
  vertical-align: bottom !important;
}
</style>